<template>
  <div class="wrap">
    <NavBar></NavBar>
    <b-container>
      <b-row>
        <b-col>
          <div class="video-con" v-if="videoPlay != null">
            <video controls autoplay :poster="videoPlay.videoCover" ref="video" :key="videoTitle">
              <source :src="videoPlay.videoPath" type="video/mp4">
            </video>
            <div class="title">{{videoPlay.videoTitle}}</div>
            <div class="operation">
              <el-button type="warning" @click="play">{{videoPlay.play ? "视频暂停" : "视频播放"}}</el-button>
              <!-- <el-button type="warning" @click="videoDownload(videoPlay)">视频下载</el-button> -->
              <el-button type="warning" @click="videoShare">视频分享</el-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="similar-video">
        <b-col class="title" sm="12" md="12" lg="12" xl="12">查看类似视频</b-col>
        <b-col sm="6" md="4" lg="3" xl="3" v-for="(item, index) in videos" :key="index">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <div class="pic" @click="toPlay(item)">
              <img :src="item.videoCover" :alt="item.videoTitle">
              <i class="el-icon-video-play"></i>
            </div>
            <div class="detail-wrap">
              <span class="video-name">{{item.videoTitle}}</span>
              <div class="bottom clearfix">
                <el-button type="text" class="button" @click="toPlay(item)">播放</el-button>
              </div>
            </div>
          </el-card>
        </b-col>
      </b-row>
    </b-container>
    <div class="gray" v-if="openGray">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="downloadProgress"></el-progress>
    </div>
    <div class="qrcode-gray" v-if="openShareQrcode" @click="closeVideoShare">
      <div class="qrcode" @click.stop>
        <vue-qr :logoSrc="imageUrl" :logoMargin="2" :text="qrcodeUrl" :size="200"></vue-qr>
      </div>
    </div>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import NavBar from "@/components/Navbar.vue";
  import footerNav from "@/components/Footer";
  import vueQr from 'vue-qr';
  export default {
    components: {
      NavBar,
      footerNav,
      vueQr
    },
    data (){
      return {
        videoId: "",
        videoPlay: null,
        videos: [],
        pageNum: 1,
        pageSize: 6,
        videoTypeId: "",
        total: "",
        openGray: false,
        downloadProgress: 0,
        videoTitle: "",
        imageUrl: require("@/../public/icon/ehome-logo.png"),
        qrcodeUrl: "",
        openShareQrcode: false
      }
    },
    created() {
      this.init();
    },
    beforeUpdate() {
      this.monitor();
    },
    methods: {
      init(){
        this.qrcodeUrl = window.location.href;
        this.videoId = this.$route.query.videoId;
        this.videoTypeId = this.$route.query.videoTypeId;
        this.getVideo();
        this.getVideos();
      },
      // 获取播放视频
      getVideo(){
        this.$api.video({
          id: this.videoId
        }).then(res => {
          if(res.status == 100){
            this.videoPlay = res.data;
            this.$set(this.videos, 'play', false);
            this.videoTitle = res.data.videoTitle;
            
          }
        }).catch(err => console.error(err))
      },
      // 获取类似视频
      getVideos(){
        this.$api.videos({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          vedioType: this.videoTypeId
        }).then(res => {
          if(res.status == 100){
            this.pageSize = res.data.pageSize;
            this.total = res.data.total;
            this.videos = res.data.list;
            this.$set(this.videos, 'play', false);
          }
        }).catch(err => console.error(err))
      },
      // 用户点击切换类似的视频
      toPlay(obj){
        this.videoId = obj.videoId;
        this.getVideo();
      },
      // 播放视频
      play(){
        this.$nextTick(() => {
          if(this.$refs.video.paused){
            this.$refs.video.play();
            this.$set(this.videoPlay, 'play', true);
          }else{
            this.$refs.video.pause();
            this.$set(this.videoPlay, 'play', false);
          }
        })
      },
      // 监听视频播放或暂停
      monitor(){
        this.$nextTick(() => {
          this.$refs.video.addEventListener('play', () => {
            this.$set(this.videoPlay, 'play', true);
          }, false)
          this.$refs.video.addEventListener('pause', () => {
            this.$set(this.videoPlay, 'play', false);
          }, false)
        })
      },
      // 通过URL地址把视频转为blob对象
      async url2Blob(url, filename){
        const res = await new Promise(resolve => {
          var xhr = new XMLHttpRequest();
          xhr.open("get", url, true);
          // 监听下载进度事件
          this.openGray = true;
          xhr.addEventListener("progress", e => {
            if(e.lengthComputable){
              let percentComplete = e.loaded / e.total;
              this.downloadProgress = parseInt(percentComplete*100);
            }
          })
          xhr.responseType = "blob";
          xhr.onload = function(){
            if(this.status == 200){
              resolve(this.response);
            }
          };
          xhr.send();
        })
        this.downFile(res, filename)
      },
      // 文件下载视频
      downFile(blob, filename) {
        this.openGray = false;
        var ele = document.createElement('a');// 创建下载链接
        ele.download = filename;//设置下载的名称
        ele.style.display = 'none';// 隐藏的可下载链接
        // 字符内容转变成blob地址 
        ele.href = URL.createObjectURL(blob);
        // 绑定点击时间
        document.body.appendChild(ele);
        ele.click();
        // 然后移除
        document.body.removeChild(ele);
      },
      // 下载视频
      videoDownload(obj){
        this.url2Blob(obj.videoPath, obj.videoTitle)
      },
      // 视频分享
      videoShare(){
        this.openShareQrcode = !this.openShareQrcode;
      },
      // 点击遮罩层关闭分享二维码
      closeVideoShare(){
        this.videoShare();
      }
      
    }
  }
</script>

<style lang="scss" scoped>
  .wrap{
    position: relative;
    width: 100%;
    .video-con{
      margin: 30px 0;
      video{
        width: 100%;
      }
      .title,
      .operation{
        margin-bottom: 5px;
        text-align: left;
      }
    }
    .similar-video{
      text-align: left;
      .title{
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 24px;
        color: #333;
      }
      .el-card{
        margin-bottom: 20px;
        img{
          width: 100%;
        }
        .pic{
          position: relative;
          cursor: pointer;
          .el-icon-video-play{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            font-weight: 400;
            font-size: 40px;
            color: #fff;
            z-index: 100;
          }
        }
        
        .detail-wrap{
          padding: 10px;
          text-align: left;
        }
        .video-name{
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          overflow: hidden;
        }
        .bottom .button{
          padding: 0;
          color: #727171;
        }
      }
    }
    .gray,
    .qrcode-gray{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
      z-index: 1000;
      .el-progress{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        transform: translate(-50%, -50%);
        z-index: 1001;
      }
      .qrcode{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
